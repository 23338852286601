import styles from './Modal.module.css'

import { defaultTrue } from '@peachy/utility-kit-pure'
import createFocusTrap from 'solid-focus-trap'
import { createComputed, createEffect, createSignal, ParentProps, type Setter } from 'solid-js'
import { Portal, Show } from 'solid-js/web'
import { withViewTransition } from '../../functions/withViewTransition'
import { onEscapeKeyWhile } from '../../signals/keyboard/KeyboardController'

export type ModalProps = ParentProps<{
    class?: string

    enableTransitions?: boolean // default false
    transitionMarkerClass?: string

    isOpen: boolean
    onDismiss: () => void   

    dissmissOnBackgroundClick?: boolean // default true
    dissmissOnEscapeKey?: boolean // default true

    trapFocus?: boolean // default true 

    locatorElement?: HTMLElement
    // pass a function to locate the modal?
    // locator?: (locatorElement: HTMLDivElement) => {} 

}>



export function Modal(props: ModalProps) {

    const [isOpen, setIsOpen] = createSignal(false)

    createEffect(() => {
        if (props.isOpen) {
            
            if (!isOpen()) {
                console.log('modal opening');
                withViewTransition({
                    transitionMarkerClass: props.transitionMarkerClass,
                    condition: props.enableTransitions,
                    updateDom: () => {
                        setIsOpen(true)
                    }
                })
            }

        } else {
            if (isOpen()) {

                console.log('modal closing');

                withViewTransition({
                    transitionMarkerClass: props.transitionMarkerClass,
                    condition: props.enableTransitions,
                    updateDom: () => {
                        setIsOpen(false)
                    }
                })
            }
        }
    })

    onEscapeKeyWhile(
        () => props.isOpen && defaultTrue(props.dissmissOnEscapeKey), 
        props.onDismiss
    )


    return (
        <Show when={isOpen()}>
            <Portal>
                <ModalContent {...props}/>
            </Portal>
        </Show>
    )
}

function ModalContent(props: ModalProps) {

    const [modalRef, setModalRef] = createSignal<HTMLElement>()

    const locatorStyle = () => {
        if (props.locatorElement) {
            const bounds = props.locatorElement.getBoundingClientRect()
            return {
                position: 'absolute',
                transform: 'translate(-50%, 1em)',
                top: `${bounds.bottom}px`,
                left: `${bounds.left + bounds.width / 2}px`,
            } as any
        } else {
            return {}
        }
    }


    createFocusTrap({ 
        element: modalRef, 
        enabled: () => props.isOpen && defaultTrue(props.trapFocus)
    })

    const onBackgroundClick = (e: MouseEvent) => {
        if (defaultTrue(props.dissmissOnBackgroundClick)) {
            props.onDismiss()
            e.stopPropagation()
        }
    }

    return (
        <div class={styles.ModalPane} data-modal-pane onClick={onBackgroundClick}>
            <aside onClick={e => e.stopPropagation()} class={props.class} ref={setModalRef} style={locatorStyle()}>
                {props.children}
            </aside>
        </div>
    )
}

