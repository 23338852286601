import { DatePickerModal, type DatePickerModalProps } from '@peachy/web-client-kit-library' 

import styles from './SovereignDatePicker.module.css'

export function SovereignDatePicker(props: DatePickerModalProps) {
    return (
        <DatePickerModal {...props} class={styles.DatePicker} appearance={
            {
                header: styles.DatePickerHeader,
                nudgeSelector: styles.NudgeSelector,
                nudgeSelectors: styles.NudgeSelectors,
                nudgeLeft: styles.nudgeLeft,
                nudgeRight: styles.nudgeRight,
                monthTable: styles.monthTable,
                weekNames: styles.weekNames,
                allDays: styles.allDays,
                inMonth: styles.inMonth,
                outMonth: styles.outMonth,
                selected: styles.selected,
            }
        }/>
    )
}

