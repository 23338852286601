import Logo from './sovereign-health-care.svg'
import styles from './Header.module.css'
import { Modal } from '@peachy/web-client-kit-library'
import { Iam } from '../../../../components/Iam/Iam'
import { createSignal } from 'solid-js'

import { RouteTransitionLink } from '@peachy/web-client-kit-library'

export function Header() {

    const [isModalOpen, setIsModalOpen] = createSignal(false)

    return (<>
            <header class={styles.Header}>
                <nav>
                    <RouteTransitionLink href="/quotes">Quotes</RouteTransitionLink>
                    <RouteTransitionLink href="/overview">Overview</RouteTransitionLink>
                    <RouteTransitionLink href="/claims">Claims</RouteTransitionLink>
                    <RouteTransitionLink href="/documents">Documents</RouteTransitionLink>
                    <RouteTransitionLink href="/scratch">Scratch!</RouteTransitionLink>
                </nav>
                <Logo class={styles.Logo}/>
                <nav>
                    <i tabindex="0" onClick={() => setIsModalOpen(true)} class="fas fa-user-circle"/>
                </nav>
            </header>
            <Modal enableTransitions={true} isOpen={isModalOpen()} onDismiss={() => setIsModalOpen(false)}><Iam/></Modal>
    </>)
}