import styles from './Footer.module.css'
import { classList } from '@peachy/web-client-kit-library'

export type FooterProps = {
    class?: string
}

export function Footer(props: FooterProps) {
    return (
        <footer class={classList(styles.Footer, props.class)}>
            Footer
        </footer>
    )
}