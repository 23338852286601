import { Match, Switch } from 'solid-js'
import { useIamService } from '../../services/iam/IamController'
import { IamEmailSignal } from './iam-kit'
import { CompleteNewPassword } from './iam-stages/CompleteNewPassword'
import { ResetPassword } from './iam-stages/ResetPassword'
import { SignIn } from './iam-stages/SignIn'
import { SignUp } from './iam-stages/SignUp'
import { ConfirmEmail } from './iam-stages/ConfirmEmail'
import { SignOut } from './iam-stages/SignOut'



export type IamPanelProps = IamEmailSignal & {
    class?: string
    viewTransitionName?: string
    allowSignUp?: boolean
}


export function IamPanel(props: IamPanelProps) {

    const iamService = useIamService()

    return (<>
        <section class={props.class} data-busy={iamService.isBusy()}>
            <Switch>
                <Match when={iamService.isInState('Anon')}>
                    <SignIn email={props.email} setEmail={props.setEmail}/>
                </Match>
                <Match when={iamService.isInState('SignUpRequested')}>
                    <SignUp email={props.email} setEmail={props.setEmail}/>
                </Match>
                <Match when={iamService.isInState('PasswordResetRequested')}>
                    <ResetPassword email={props.email} setEmail={props.setEmail}/>
                </Match>
                <Match when={iamService.isInState('NewPasswordRequired')}>
                    <CompleteNewPassword/>
                </Match>
                <Match when={iamService.isInState('EmailCodeRequired')}>
                    <ConfirmEmail email={props.email} setEmail={props.setEmail}/>
                </Match>
                <Match when={iamService.isInState('SignedIn')}>
                    <SignOut/>
                </Match>
            </Switch>
        </section>
    </>)
}