import { Header } from './Header/Header'
import { Footer } from './Footer/Footer'
import styles from './MainLayout.module.css'

import type { ParentProps } from 'solid-js'

export function MainLayout(props: ParentProps) {
    return (<>
        <Header />
        <main class={styles.Main}>{props.children}</main>
        <Footer />
    </>)
}