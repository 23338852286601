import { Modal, type ModalProps } from "../Modal/Modal"
import { DatePicker, type DatePickerProps } from "./DatePicker"   


export type DatePickerModalProps = ModalProps & DatePickerProps


export function DatePickerModal(props: DatePickerModalProps) {
    return (
        <Modal isOpen={props.isOpen} onDismiss={props.onDismiss} class={props.class}>
            <DatePicker 
                minDate={props.minDate}
                maxDate={props.maxDate}
                selectedDate={props.selectedDate}
                headerText={props.headerText}
                submitText={props.submitText}
                onSelect={props.onSelect}
                appearance={props.appearance}
            />
        </Modal>
    )
}

