import { delayedValue, type LookupProps } from '@peachy/web-client-kit-library'
import { createSignal, type Accessor, type Setter } from 'solid-js'
import { SovereignLookup } from '../lookup/SoveriegnLookup'
import styles from './LookupDemo.module.css'



export function LookupDemo() {

    const [selection, setSelection] = createSignal<string>()
    return (
        <label class={styles.fieldContainer}>

            <SovereignLookup<string> 
                minQueryLength={3}
                placeholder='Say something'
                maxQueryRateMillis={200}
                executeQuery={dummyResource as () => Promise<string[]>}
                onSelect={setSelection}
            />
        </label>
    )
}


const dummyResource = (searchTerm: string) => {
    const randomInt = Math.floor(Math.random() * 20) + 1;
    console.log('lookup', searchTerm)
    const results = []
    for (let i = 0; i < randomInt; i++) {
        results.push(`${searchTerm}-${i}`)
    }

    return delayedValue(results, 300)
}
