import {trueMod} from '@peachy/utility-kit-pure'
import {createThrottledSignal} from './createThrottledSignal'
import {type Accessor, createComputed, createResource, createSignal, type Resource} from 'solid-js'


export type LookupSignal<T> = {
    value: Accessor<string>

    query: Accessor<string>
    setQuery: (query: string) => void

    results: Resource<T[]>
    isStale: Accessor<boolean>
    setIsStale: (isStale: boolean) => void

    cursorIndex: Accessor<number>
    setCursorIndex: (index: number) => void
    incrementCursorIndex: () => void
    decrementCursorIndex: () => void

    selection: Accessor<T>
    selectAtCursor: () => boolean
    selectAtIndex: (index: number) => boolean
    resetSelection: () => void
}


export type LookupOptions<T> = {
    executeQuery: (query: string) => Promise<T[]>
    elementDisplay?: (element: T) => string
    isValidQuery?: (query: string) => boolean
    initialSelection?: T
    minQueryLength?: number
    maxQueryRateMillis: number
}


export function createLookupSignal<T>(config: LookupOptions<T>): LookupSignal<T> {

    const [query, setQuery] = createSignal<string>('')
    const [selection, setSelection] = createSignal<T>(config.initialSelection)
    const [bufferedQuery, setBufferedQuery] = createThrottledSignal<string>(query(), config.maxQueryRateMillis)

    const [isStale, setIsStale] = createSignal<boolean>(true)
    const [results] = createResource(bufferedQuery, async (bufferedQuery) => {
        console.log('createResource', bufferedQuery)
        return isValidQuery(bufferedQuery, config)
            ? config.executeQuery(bufferedQuery)
            : []
    })
    createComputed(() => {
        setIsStale(!results())
    })


    const [displayValue, setDisplayValue] = createSignal<string>(getDisplayValueForSelection(selection(), config))

    createComputed(() => {
        setDisplayValue(query())
        setBufferedQuery(query())
    })


    const [cursorIndex, setCursorIndex] = createSignal<number>(0)
    const setCursorIndexMod = (index: number) => {
        if (results.loading) return
        setCursorIndex(modLength(index, results().length))
    }
    const decrementCursorIndex = () => setCursorIndexMod(cursorIndex() - 1)
    const incrementCursorIndex = () => setCursorIndexMod(cursorIndex() + 1)



    const selectAtCursor = () => {
        return selectAtIndex(cursorIndex())
    }

    const selectAtIndex = (index: number) => {
        if (results.loading || isStale()) return false
        setCursorIndexMod(index)
        setSelection(() => results()[cursorIndex()])
        return true
    }


    const resetSelection = () => {
        if (selection()) {
            setDisplayValue(getDisplayValueForSelection(selection(), config))
            setIsStale(true)
        }
    }
    createComputed(resetSelection)


    createComputed(() => {
        if (results()) {
            setCursorIndexMod(cursorIndex())
        }
    })


    return {
        value: displayValue,

        query,
        setQuery,
        results,
        isStale,
        setIsStale: setIsStale,

        cursorIndex,
        setCursorIndex: setCursorIndexMod,
        incrementCursorIndex,
        decrementCursorIndex,

        selection,
        selectAtCursor,
        selectAtIndex,
        resetSelection,
    }
}

function isValidQuery<T>(query: string, config: LookupOptions<T>) {
    return (query.length >= (config.minQueryLength ?? 0)) && (config.isValidQuery?.(query) ?? true)
}


function getDisplayValueForSelection<T>(selection: T, config: LookupOptions<T>) {
    return selection ? config?.elementDisplay?.(selection) ?? selection.toString() : ''
}


function modLength(value: number, length: number) {
    return value ? trueMod(value, length) : 0
}
