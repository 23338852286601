import {createSignal} from 'solid-js'
import { useIamService } from '../../../services/iam/IamController'
import { isValidPassword } from '../iam-kit'
import { IamError } from '../IamError'
import { PasswordInput } from '../PasswordInput'
import { PasswordPolicy } from '../PasswordPolicy'

export function CompleteNewPassword() {
    const iamService = useIamService()

    const [password, setPassword] = createSignal('')

    const setNewPassword = () => {
        if (isValidPassword(password())) iamService.completeNewPassword(password())
    }

    let buttonRef: HTMLButtonElement

    const onCancel = () =>iamService.cancelNewPassword()


    return <>
        <h3>Change password</h3>
        <IamError/>
        <PasswordInput
                    placeholder={'new password'}
                    password={password}
                    setPassword={setPassword}
                    onEnter={setNewPassword}
        />

        <PasswordPolicy/>

        <button ref={buttonRef} onClick={setNewPassword} disabled={!isValidPassword(password())}>Change</button>
        <a onClick={onCancel}>Cancel &rarr; </a>
    </>

}
