import { createSignal, Match, Switch } from 'solid-js'
import { useIamService } from '../../../services/iam/IamController'
import { hasCredentials, IamEmailSignal, isValidEmail } from '../iam-kit'
import { IamError } from '../IamError'
import { PasswordInput } from '../PasswordInput'


export function ResetPassword(props: IamEmailSignal) {

    const iamService = useIamService()

    const [passwordCode, setPasswordCode] = createSignal('')
    const [password, setPassword] = createSignal('')

    const resetPassword = () => {
        if (hasCredentials(props.email, password)) {
            iamService.resetPassword(props.email(), password(), passwordCode())
        }
    }

    const sendCode = () => {
        if (isValidEmail(props.email())) iamService.requestPasswordCode(props.email())
    }

    const onCancel = () => iamService.cancelPasswordReset()

    return <>
        <h3>Reset password</h3>
        <IamError/>
        <input type="email" value={props.email()} onInput={(e) => props.setEmail(e.currentTarget.value)} placeholder={'email address'}/>
        <Switch>
            <Match when={iamService.isInState('PasswordResetRequested.Idle')}>
                <button onClick={sendCode} disabled={!isValidEmail(props.email())}>Send code</button>
            </Match>
            <Match when={iamService.isInState('PasswordResetRequested.PasswordCodeRequired')}>
                <input type="text" value={passwordCode()} onInput={(e) => setPasswordCode(e.currentTarget.value)} placeholder={'code'}/>
                <a onClick={sendCode}>Resend code &rarr; </a>
                <PasswordInput
                    placeholder={'new password'}
                    password={password}
                    setPassword={setPassword}
                    onEnter={resetPassword}
                />
                <button onClick={resetPassword} disabled={!hasCredentials(props.email, password)}>Reset Password</button>
            </Match>
        </Switch>
        <a onClick={onCancel}>Cancel &rarr; </a>
    </>
}
