import { defaultTrue } from '@peachy/utility-kit-pure'



export type ViewTransitionOptions = {
    transitionMarkerClass?: string
    types?: string[]
    condition?: boolean
    updateDom: () => void
}


export function withViewTransition(options: ViewTransitionOptions): ViewTransition {

    if (document?.startViewTransition && defaultTrue(options.condition)) {
        
        if (options.transitionMarkerClass) {
            document.body.classList.add(options.transitionMarkerClass)
        }

        const transition = document.startViewTransition({
            update: options.updateDom,
            types: options.types
        })

        if (options.transitionMarkerClass) {
            transition.finished.then(() => {
                document.body.classList.remove(options.transitionMarkerClass)
            })
        }

        return transition

    } else {
        options.updateDom()
        return null
    }
}

