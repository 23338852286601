import {classList, Lookup, type LookupProps} from '@peachy/web-client-kit-library'
import styles from './SovereignLookup.module.css'


export function SovereignLookup<T>(props: LookupProps<T>) {
    
    return <Lookup 
        {...props}
        lookupInputClass={classList(styles.lookupInput, props.lookupInputClass)}
        lookupListClass={classList(styles.lookupList, props.lookupListClass)}
        showHideTransitionMarkerClass={styles.lookupShowHideTransition}
        updateTransitionMarkerClass={styles.lookupUpdateTransition}
        enableTransitions={true}
    />
}
