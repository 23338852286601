import { Show, createSignal } from 'solid-js'
import { useIamService } from '../../../services/iam/IamController'
import { IamError } from '../IamError'
import { PasswordInput } from '../PasswordInput'
import { hasCredentials, type IamEmailSignal } from '../iam-kit'



export function SignIn(props: IamEmailSignal) {
    const iamService = useIamService()

    const [password, setPassword] = createSignal('Pokqwdpokqwd1!')

    const signIn = () => {
        if (hasCredentials(props.email, password)) iamService.signIn(props.email(), password())
    }

    const requestSignUp = () => iamService.requestSignUp()

    const onForgot = () => iamService.requestPasswordReset()
    

    return <>
        <h3>Sign In</h3>
        <IamError />
        <input type="email" value={props.email()} onInput={(e) => props.setEmail(e.currentTarget.value)} placeholder={'email address'} />
        {/* <a onClick={requestSignUp}>Create account &rarr;</a> */}
        <PasswordInput password={password} setPassword={setPassword} onEnter={signIn} />
        
        
        <button onClick={signIn} disabled={!hasCredentials(props.email, password)}>Sign in</button>

        <Show when={iamService.error() !== 'PasswordExpired'}>
            <a onClick={onForgot}>Forgotten password? &rarr;</a>
        </Show>
    </>
}

