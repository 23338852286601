
export type Primitive = string | number | boolean
// Type of
export const isString = (x: unknown): x is string => typeof x === 'string'
export const isNumber = (x: unknown): x is number => typeof x === 'number'
export const isBoolean = (x: unknown): x is boolean => typeof x === 'boolean'
export const isFunction = (x: unknown): x is Function => typeof x === 'function'
export const isArray = (x: unknown): x is any[] => Array.isArray(x)
export const isObject = (x: unknown): x is { [k: string]: unknown } => !!x && typeof x === 'object' && !isArray(x)
export const isNullish = (value: any): value is null | undefined => (value === null || value === undefined)
export const isExactlyNull = (value: any): value is null => value === null
export const isNotNullish = (value: any) => !isNullish(value)

export const isPrimitive = (x: unknown): x is Primitive => isNumber(x) || isString(x) || isBoolean(x)

export const isUndefined = (x: unknown): x is undefined => typeof x === 'undefined'

export const isPromise = (x: any): x is Promise<any> => typeof x === 'object' && 'then' in x && typeof x.then === 'function'

export const isDateObject = (x: any): x is Date => x instanceof Date

export function assertNotNullish(value: unknown, message?: string): asserts value {
    if (isNullish(value)) {
        throw message || 'Value is null'
    }
}


export function defaultTrue(value: boolean | undefined): boolean {
    return isBoolean(value) ? value : true
}

