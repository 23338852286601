
import { Lorem } from '@peachy/web-client-kit-library'
import { DatePickerDemo } from '../../../components/dummy/DatePickerDemo/DatePickerDemo'
import { LookupDemo } from '../../../components/dummy/LookupDemo'
import { Theme } from '../../../components/dummy/Theme/Theme'
import styles from './Scratch.module.css'


export function Scratch() {


    return (
        <div class={styles.Scratch}>
            <DatePickerDemo />
            <LookupDemo />
            <Theme />
            <Lorem paragraphs={20} />
        </div>
    )
}


