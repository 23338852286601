import {ON, TAGS, createStateMachine, type StatePaths, type TransitionInterceptor} from '../../state-machine/solid-state-machine'
const iamDef = {
    'Anon': {
        'Idle': {
            [ON]: {
                signBackIn: () => iamDef.Anon.SigningBackIn,
                signIn: () => iamDef.Anon.SigningIn,
                requestSignUp: () => iamDef.SignUpRequested,
                requestPasswordReset: () => iamDef.PasswordResetRequested
            }
        },
        'SigningBackIn': {
            [TAGS]: 'busy',
            [ON]: {
                signBackInSuccess: () => iamDef.SignedIn,
                signBackInFailure: () => iamDef.Anon,
            },
        },
        'SigningIn': {
            [TAGS]: 'busy',
            [ON]: {
                requiresNewPassword: () => iamDef.NewPasswordRequired,
                requiresEmailCode: () => iamDef.EmailCodeRequired,
                signInSuccess: () => iamDef.SignedIn,
                signInFailure: () => iamDef.Anon,
            }
        },
    },
    'EmailCodeRequired': {
        'Idle': {
            [ON]: {
                confirmEmailCode: () => iamDef.EmailCodeRequired.ConfirmingEmailCode,
                requestEmailCode: () => iamDef.EmailCodeRequired.RequestingEmailCode,
                cancelEmailCode: () => iamDef.Anon
            },
        },
        'ConfirmingEmailCode': {
            [TAGS]: 'busy',
            [ON]: {
                confirmEmailCodeSuccess: () => iamDef.SignedIn,
                confirmEmailCodeFailure: () => iamDef.EmailCodeRequired,
            },
        },
        'RequestingEmailCode': {
            [TAGS]: 'busy',
            [ON]: {
                requestEmailCodeSuccess: () => iamDef.EmailCodeRequired,
                requestEmailCodeFailure: () => iamDef.EmailCodeRequired,
            },
        },
    },
    'NewPasswordRequired': {
        'Idle': {
            [ON]: {
                completeNewPassword: () => iamDef.NewPasswordRequired.CompletingNewPassword,
                cancelNewPassword: () => iamDef.Anon,
            },
        },
        'CompletingNewPassword': {
            [TAGS]: 'busy',
            [ON]: {
                newPasswordSuccess: () => iamDef.SignedIn,
                newPasswordFailure: () => iamDef.NewPasswordRequired,
            },
        },
    },
    'PasswordResetRequested': {
        'Idle': {
            [ON]: {
                requestPasswordCode: () => iamDef.PasswordResetRequested.RequestingPasswordCode,
                cancelPasswordReset: () => iamDef.Anon,
            }
        },
        'RequestingPasswordCode': {
            [TAGS]: 'busy',
            [ON]: {
                requestPasswordCodeSuccess: () => iamDef.PasswordResetRequested.PasswordCodeRequired,
                requestPasswordCodeFailure: () => iamDef.PasswordResetRequested,
            },
        },
        'PasswordCodeRequired': {
            'Idle': {
                [ON]: {
                    requestPasswordCode: () => iamDef.PasswordResetRequested.PasswordCodeRequired.RequestingPasswordCode,
                    cancelPasswordReset: () => iamDef.Anon,
                    resetPassword: () => iamDef.PasswordResetRequested.PasswordCodeRequired.ResettingPassword,
                },
            },
            'RequestingPasswordCode': {
                [TAGS]: 'busy',
                [ON]: {
                    requestPasswordCodeSuccess: () => iamDef.PasswordResetRequested.PasswordCodeRequired,
                    requestPasswordCodeFailure: () => iamDef.PasswordResetRequested.PasswordCodeRequired,
                },
            },
            'ResettingPassword': {
                [TAGS]: 'busy',
                [ON]: {
                    resetPasswordSuccess: () => iamDef.Anon,
                    resetPasswordFailure: () => iamDef.PasswordResetRequested.PasswordCodeRequired,
                },
            }
        },
    },
    'SignUpRequested': {
        'Idle': {
            [ON]: {
                signUp: () => iamDef.SignUpRequested.SigningUp,
                cancelSignUp: () => iamDef.Anon,
            },
        },
        'SigningUp': {
            [TAGS]: 'busy',
            [ON]: {
                signUpSuccess: () => iamDef.Anon,
                signUpFailure: () => iamDef.SignUpRequested,
            },
        },  
    },

    'SignedIn': {
        'Idle': {
            [ON]: {
                signOut: () => iamDef.SignedIn.SigningOut,
            }
        },
        'SigningOut': {
            [TAGS]: 'busy',
            [ON]: {
                signOutSuccess: () => iamDef.Anon,
                signOutFailure: () => iamDef.Anon,
            }
        },
    },
} as const


export type IamStates = StatePaths<typeof iamDef>


export function createIamStateMachine(transitionInterceptor: TransitionInterceptor) {
    return createStateMachine(iamDef, transitionInterceptor)
}
