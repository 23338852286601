

export { Lookup } from './src/components/Lookup/Lookup'
export type { LookupProps } from './src/components/Lookup/Lookup'
export { DatePicker } from './src/components/DatePicker/DatePicker'
export type { DatePickerProps } from './src/components/DatePicker/DatePicker'
export { DatePickerModal } from './src/components/DatePicker/DatePickerModal'
export type { DatePickerModalProps } from './src/components/DatePicker/DatePickerModal'
export { RouteTransitionLink } from './src/components/RouteTransitionLink/RouteTransitionLink'


export { createPriorSignal } from './src/signals/createPriorSignal'
export { createBufferedSignal } from './src/signals/createBufferedSignal'
export { createLookupSignal } from './src/signals/createLookupSignal'
export { createThrottledSignal } from './src/signals/createThrottledSignal'

export { useKeyUpSignal as useKeyboardSignal } from './src/signals/useKeyUpSignal'


export { classList } from './src/functions/classList'
export { delayedValue } from './src/functions/delayedValue'
export { scrollToElement } from './src/functions/scrollToElement'
export { popTransition } from './src/functions/popTransition'
export { withViewTransition } from './src/functions/withViewTransition'
export { dynamicCssKeyboardModifierClasses } from './src/css-dynamics/dynamic-classes/dynamicCssKeyboardModifierClasses'
export { dynamicCssPageScrollTop } from './src/css-dynamics/dynamic-variables/dynamicCssPageScrollTopVariable'

export { Lorem } from './src/components/Lorem/Lorem'


export { type IamApi } from './src/services/iam/IamApi'
export { type IamUser } from './src/services/iam/IamApi'
export { type AuthenticationError } from './src/services/iam/IamApi'
export { type IamService } from './src/services/iam/IamService'

export { createIamApi } from './src/services/iam/IamApi'
export { createIamService } from './src/services/iam/IamService'
export { IamController } from './src/services/iam/IamController'
export { useIamService } from './src/services/iam/IamController'


export { IamPanel } from './src/components/Iam/IamPanel'
export { Modal } from './src/components/Modal/Modal'

export { KeyboardController } from './src/signals/keyboard/KeyboardController'



export { onKeyDownWhile } from './src/signals/keyboard/KeyboardController'
export { onKeyUpWhile } from './src/signals/keyboard/KeyboardController'
export { onEscapeKeyWhile } from './src/signals/keyboard/KeyboardController'

        import * as me from '@peachy/web-client-kit-library'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/web-client-kit/library'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/web-client-kit-library'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    