import styles from './Theme.module.css'

export function Theme() {
    return (
        <div class={styles.themeContainer}>
            <h1>Theme</h1>
            <div class={styles.themeGrid}>
                <div class={styles.sovereignPurplePale}>
                    <h3>Sovereign Purple Pale</h3>
                </div>
                <div class={styles.sovereignPurpleLight}>
                    <h3>Sovereign Purple Light</h3>
                </div>
                <div class={styles.sovereignPurple}>
                    <h3>Sovereign Purple</h3>
                </div>
                <div class={styles.sovereignPurpleDark}>
                    <h3>Sovereign Purple Dark</h3>
                </div>

                <div class={styles.sovereignGreenPale}>
                    <h3>Sovereign Green Pale</h3>
                </div>
                <div class={styles.sovereignGreen}>
                    <h3>Sovereign Green</h3>
                </div>
                <div class={styles.sovereignGreen}>
                    <h3>Sovereign Green</h3>
                </div>
                <div class={styles.sovereignGreenDark}>
                    <h3>Sovereign Green Dark</h3>
                </div>


                <div class={styles.sovereignBluePale}>
                    <h3>Sovereign Blue Pale</h3>
                </div>
                <div class={styles.sovereignBlueLight}>
                    <h3>Sovereign Blue Light</h3>
                </div>
                <div class={styles.sovereignBlue}>
                    <h3>Sovereign Blue</h3>
                </div>
                <div class={styles.sovereignBlueDark}>
                    <h3>Sovereign Blue Dark</h3>
                </div>

                <div>
                    <h3></h3>
                </div>
                <div class={styles.sovereignGotoTurquoiseLight}>
                    <h3>Goto Turquoise Light</h3>
                </div>
                <div class={styles.sovereignGotoTurquoise}>
                    <h3>Goto Turquoise</h3>
                </div>
                <div>
                    <h3></h3>
                </div>
            </div>
        </div>
    )
}
