import { createSignal, Match, Switch } from 'solid-js'
import { useIamService } from '../../../services/iam/IamController'
import { hasCredentials, IamEmailSignal, isValidEmail } from '../iam-kit'
import { IamError } from '../IamError'
import { PasswordInput } from '../PasswordInput'


export function ConfirmEmail(props: IamEmailSignal) {

    const iamService = useIamService()

    const [password, setPassword] = createSignal('')
    const [emailCode, setEmailCode] = createSignal('')


    const allValid = () => {
        return hasCredentials(props.email, password) && emailCode()
    }

    const confirmEmail = () => {
        if (allValid()) iamService.confirmEmailCode(props.email(), password(), emailCode())
    }

    const sendCode = () => {
        if (isValidEmail(props.email())) iamService.requestEmailCode(props.email())
    }

    const cancel = () => iamService.cancelEmailCode()

    return <>
        <h3>Confirm email</h3>
        <IamError/>
        <input type="email" value={props.email()} onInput={(e) => props.setEmail(e.currentTarget.value)} placeholder={'email address'}/>
        <Switch>
            <Match when={iamService.isInState('EmailCodeRequired.Idle')}>
                <button onClick={sendCode} disabled={!isValidEmail(props.email())}>Send code</button>
            </Match>
            <Match when={iamService.isInState('PasswordResetRequested.PasswordCodeRequired')}>
                <input type="text" value={emailCode()} onInput={(e) => setEmailCode(e.currentTarget.value)} placeholder={'code'}/>
                <a onClick={sendCode}>Resend code &rarr; </a>
                <PasswordInput
                    placeholder={'new password'}
                    password={password}
                    setPassword={setPassword}
                    onEnter={confirmEmail}
                />
                <button onClick={confirmEmail} disabled={!allValid}>Confirm</button>
            </Match>
        </Switch>
        <a onClick={cancel}>Cancel &rarr; </a>
    </>
}
