import { Router, Route } from "@solidjs/router";


import { IamController, KeyboardController, dynamicCssKeyboardModifierClasses, dynamicCssPageScrollTop } from '@peachy/web-client-kit-library'
import { MainLayout } from './layouts/main-layout/MainLayout';
import { Overview } from './pages/Overview/Overview';
import { Scratch } from './pages/Scratch/Scratch';



export function App() {
    dynamicCssKeyboardModifierClasses()
    dynamicCssPageScrollTop()
    return (
        <KeyboardController>
            <IamController iamConfigType={'IamPortalConfig'}>

                <Router root={MainLayout}>
                    <Route path="/" component={Overview} />
                    <Route path="/overview" component={Overview} />
                    <Route path="/scratch" component={Scratch} />
                </Router>

            </IamController>
        </KeyboardController>
    )
}


