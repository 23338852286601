import { Auth, type CognitoUser } from '@aws-amplify/auth'
import { useServicePatch } from '../useServicePatch'
import { fetchServiceConfig } from '../fetchServiceConfig'
type AuthType = typeof Auth


export type IamConfigType = 'IamPortalConfig' | 'MEMBERS'


export type IamApi = {
    extractError: (e: any) => AuthenticationError
} & Pick<AuthType,
    | 'currentAuthenticatedUser'
    | 'changePassword'
    | 'completeNewPassword'
    | 'confirmSignIn'
    | 'confirmSignUp'
    | 'verifyUserAttribute'
    | 'verifyUserAttributeSubmit'
    | 'updateUserAttributes'
    | 'forgotPassword'
    | 'forgotPasswordSubmit'
    | 'resendSignUp'
    | 'signIn'
    | 'signOut'
    | 'signUp'>



export type IamUser = CognitoUser
const region = 'eu-west-2'

export async function createIamApi(iamConfigType: IamConfigType): Promise<IamApi> {
    const aug = Auth as any
    aug.extractError = extractError
    await configureAws(iamConfigType)
    return aug
}




function isTempPasswordExpireError(message: string): boolean {
    return message.includes('Temporary password has expired and must be reset by an administrator')
}

export type AuthenticationError =
    'PasswordPolicyException' | 'PasswordExpired' | 'InvalidCredentials' | 'ExceededMaxAttempts' | 'UserNotFound' | 'Unknown'


function extractError(e: any): AuthenticationError {
    const code = e.code
    const message = e.toString()

    switch (code) {
        case 'InvalidPasswordException':
            return 'PasswordPolicyException'
        case 'NotAuthorizedException':
            return isTempPasswordExpireError(message) ? 'PasswordExpired' : 'InvalidCredentials'
        case 'LimitExceededException':
            return 'ExceededMaxAttempts'
        case 'UserNotFoundException':
            return 'UserNotFound'
        default:
            return 'Unknown'
    }
}





async function configureAws(iamConfigType: IamConfigType) {

    const servicePatchUrl = useServicePatch()

    //auth config
    const iamConfig = await fetchServiceConfig(iamConfigType, servicePatchUrl)

    Auth.configure({
        userPoolId: iamConfig.userPoolId,
        userPoolWebClientId: iamConfig.userPoolWebClientId,
        identityPoolId: iamConfig.identityPoolId,
        region,
    })
}




