import {createSignal, onCleanup, onMount} from 'solid-js'
import {KeyboardKey} from './keyboard/keyboardKeys'

export function useKeyUpSignal(key?: KeyboardKey) {
    const [keyup, setKeyup] = createSignal<KeyboardEvent>()

    onMount(() => {
        const handler = (e: KeyboardEvent) => {
            if (key && e.key !== key) return
            setKeyup(e)
        }
        window.addEventListener('keyup', handler)
        onCleanup(() => {
            window.removeEventListener('keyup', handler)
        })
    })
    return keyup
}
