import { createSignal } from 'solid-js'
import { useIamService } from '../../../services/iam/IamController'
import { IamError } from '../IamError'
import { PasswordInput } from '../PasswordInput'
import { hasCredentials, type IamEmailSignal } from '../iam-kit'



export function SignOut() {
    const iamService = useIamService()

    const [password, setPassword] = createSignal('')

    const signOut = () => iamService.signOut()

    return <>
        <h3>Sign Out</h3>
        <IamError/>
        <button onClick={signOut} >Sign out</button>
    </>
}

