import { SovereignDatePicker } from '../../SovereignDatePicker/SovereignDatePicker';
import { createSignal } from 'solid-js'

const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))


export function DatePickerDemo() {

    const [isOpen, setIsOpen] = createSignal(false)
    const [selectedDate, setSelectedDate] = createSignal(new Date())

    const onSelect = (date: Date) => {
        setSelectedDate(date)
        setIsOpen(false)
    }

    return (
        <div>
            <button onClick={() => setIsOpen(true)}>Pick Date</button>

            <SovereignDatePicker
                isOpen={isOpen()}
                onDismiss={() => setIsOpen(false)}
                minDate={minDate}
                maxDate={maxDate}
                selectedDate={selectedDate()}
                headerText="Select a date"
                submitText="Submit"
                onSelect={onSelect}
            />

            <p>
                {selectedDate().toLocaleDateString()}
            </p>

        </div>
    )
}

