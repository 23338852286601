import { createEffect, type Accessor, type Setter } from 'solid-js'
import { useKeyUpSignal } from '../../signals/useKeyUpSignal'


export type PasswordInputProps = {
    password: Accessor<string>
    setPassword: Setter<string>
    reveal?: Accessor<boolean>
    onEnter?: () => void
    placeholder?: string
    class?: string
    ref?: HTMLInputElement
}



export function PasswordInput(props: PasswordInputProps) {

    const mode = () => props.reveal?.() ? 'text' : 'password'
    // const mode = () => 'text'

    const k = useKeyUpSignal('Enter')
    
    createEffect(() => k() && props.onEnter?.())
    
    return <input 
        ref={props.ref}
        value={props.password()}
        onInput={(e) => props.setPassword(e.currentTarget.value)}
        placeholder={props.placeholder ?? 'password'}
        type={mode()}
        class={props.class}
    />
}